import axios, { AxiosRequestConfig } from 'axios';
import { getIdToken } from 'firebase/auth';
import { auth } from 'utilities/firebase.utility';
import store from 'redux/store';

const updateRequestWithFirebaseToken = async (request) => {
  if (request?.url.includes('sendResetPasswordLink')) return request;
  const firebaseToken = await getIdToken(auth.currentUser, false);
  request.headers = {
    ...request.headers,
    Authorization: `Bearer ${firebaseToken}`
  };

  return request;
};

const updateRequestWithCalendarTokens = async (request) => {
  const firebaseToken = await getIdToken(auth.currentUser, false);
  const { user } = store.getState();

  const nylasProviderToken = user?.providers[0]?.accessToken;

  request.headers = {
    ...request.headers,
    Authorization: `Bearer ${firebaseToken}`,
    accessToken: nylasProviderToken
  };

  return request;
};

const updateRequestOnCreateEvent = async (request: AxiosRequestConfig) => {
  if (request.method === 'get') return request;

  const firebaseToken = await getIdToken(auth.currentUser, false);
  const { user } = store.getState();
  let nylasProviderToken = user?.providers[0]?.accessToken;
  if (user.role === 'coachee') {
    nylasProviderToken = user?.coach?.providers[0]?.accessToken;
  }

  request.headers = {
    ...request.headers,
    Authorization: `Bearer ${firebaseToken}`,
    accessToken: nylasProviderToken
  };

  return request;
};

axios.interceptors.request.use((request: AxiosRequestConfig) => {
  request.headers = {
    ...request.headers,
    'x-app-id': 'coaching'
  };

  if (request.url?.includes('userRol'))
    return updateRequestWithFirebaseToken(request);

  if (
    request.url?.includes('calendars/') ||
    request.url?.includes('workschedule') ||
    request.url?.includes('blockedschedule')
  ) {
    return updateRequestWithCalendarTokens(request);
  }

  if (
    request.url?.includes('/events/nylas') ||
    request.url?.includes('/session')
  )
    return updateRequestOnCreateEvent(request);

  return request;
});
