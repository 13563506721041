const userAdapted = (response) => {
  const user = response.data;

  return {
    id: user._id,
    name: user.name,
    lastname: user.lastname,
    email: user.email,
    uid: user.firebaseUID,
    role: user.role,
    photo: user.role === 'coach' ? user.urlImgCoach : user.urlImgCoachee,
    video: user?.urlVideoCoach,
    focusAreas: user.focusAreas,
    resume: user?.resume,
    howWork: user?.howWork,
    onboardingCompleted: user.onboardingCompleted,
    onboardingDate: user.onboardingDate,
    coach: user.coach,
    sessions: user.sessions || [],
    calendar: user.calendar,
    mongoID: user._id,
    providers: user.providers,
    coachees: user.role === 'coach' && user.coachees,
    evaluators: user?.evaluators,
    company: user?.company?.name,
    department: user?.department,
    cohort: user?.cohort && {
      program: user?.cohort.Program,
      startedOn: user?.cohort.StartDate
    },
    coachingProgram: user?.coachingProgram,
    phone: user?.phone,
    alternateCall: user?.linkExternalCall,
    timezone: user?.timezone,
    ratingSessionsCoach: user?.RatingSessionsCoach,
    ratingCoach: user?.RatingCoach,
    languages: user?.languages,
    additionalSessions: user?.additionalSessions ? user?.additionalSessions : 0,
    createdAt: user.createdAt,
    activeUser: user.activeUser,
    statusUser: user.statusUser,
    suspendedByCompany: user?.suspendedByCompany,
    dataStudio: user?.dataStudio
  };
};
export default userAdapted;
