import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Image
} from '@chakra-ui/react';
import Select from 'react-select';
import {
  timezonesToReactSelect,
  IANATimezones
} from 'utilities/timezones.utility';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { findIndex } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { TimeZoneSelectDialog } from 'react-timezone-map-select';
import displayToast from 'utilities/toast.utility';
import iconClock from 'assets/images/icons/zona-horaria.png';
import { useTranslation } from 'react-i18next';
import { modifyUser } from 'redux/slices/user';
import { updateCoach } from 'services/coach.service';
import { useFetchAndLoad } from 'hooks';
import { updateUserLanguagesAndTimezone } from 'services/user.service';

function PickTimezone() {
  const { timezone, mongoID, role, languages } = useSelector(
    (state) => state.user
  );
  const [openMap, setOpenMap] = useState(false);
  const [timezones] = useState(timezonesToReactSelect());
  const { t } = useTranslation('global');
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  const isCoach = role === 'coach';

  const validation = t(
    'pages.onboarding.components.pickTimezone.messages.validation'
  );
  const required = t(
    'pages.onboarding.components.pickTimezone.messages.required'
  );

  const updateTimezone = async ({ timezone }) => {
    try {
      if (isCoach) {
        await callEndpoint(updateCoach({ timezone, id: mongoID }));
      } else {
        await callEndpoint(
          updateUserLanguagesAndTimezone(mongoID, role, { timezone, languages })
        );
      }
      displayToast('Cambios guardados con éxito', 'success');
      dispatch(modifyUser({ timezone }));
    } catch (error) {
      displayToast('Error al guardar cambios', 'error');
    }
  };

  const { handleSubmit, values, errors, setValues } = useFormik({
    initialValues: timezone,
    validationSchema: Yup.object().shape({
      timezone: Yup.string().oneOf(IANATimezones, validation).required(required)
    }),
    onSubmit: async (formValues) => updateTimezone(formValues)
  });

  const handleCloseMap = (mapTimezone) => {
    setValues({ ...values, timezone: mapTimezone });
    setOpenMap(false);
  };

  const getIndexOfTimezone = (timezone) =>
    findIndex(timezones, {
      value: timezone
    });

  const setTimeZoneFromUser = () => {
    const index = getIndexOfTimezone(timezone);
    if (index >= 0) setValues({ ...values, timezone: timezones[index].value });
  };

  const handleSelectChange = (option) => {
    setValues({ ...values, timezone: option.value });
  };

  useEffect(() => {
    if (timezones) setTimeZoneFromUser();
  }, [timezones]);

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
      className="TimezoneSelection"
    >
      <Image
        src={iconClock}
        width="100"
        className="TimezoneSelection__icon"
        objectFit="cover"
      />
      <h3 className="TimezoneSelection__title">
        {t('pages.onboarding.components.pickTimezone.title')}
      </h3>
      <p className="TimezoneSelection__subtitle">
        {t('pages.onboarding.components.pickTimezone.subtitle')}
      </p>
      <div className="TimezoneSelection__picker card">
        <form
          className="TimezoneSelection__form"
          onSubmit={handleSubmit}
          id="timezone"
        >
          <FormControl isInvalid={errors.timezone}>
            <Select
              defaultValue={timezones[getIndexOfTimezone(values.timezone)]}
              options={timezones}
              onChange={handleSelectChange}
              className="TimezoneSelection__select"
              value={timezones[getIndexOfTimezone(values.timezone)]}
            />

            <FormHelperText textAlign="left" fontSize="xl" mb="10">
              {t('pages.onboarding.components.pickTimezone.textHelper')}
            </FormHelperText>

            <Button
              className="Button--primary TimezoneSelection__open"
              onClick={() => setOpenMap(true)}
            >
              {t('pages.onboarding.components.pickTimezone.openMap')}
            </Button>

            {errors?.timezone ? (
              <FormErrorMessage fontSize="lg">
                {errors.timezone}
              </FormErrorMessage>
            ) : null}
          </FormControl>

          <TimeZoneSelectDialog
            open={openMap}
            timeZoneName={values.timezone}
            onClose={handleCloseMap}
            description={t(
              'pages.onboarding.components.pickTimezone.selectDialog.description'
            )}
            buttonLabelCancel={t(
              'pages.onboarding.components.pickTimezone.selectDialog.cancelButton'
            )}
            title={t(
              'pages.onboarding.components.pickTimezone.selectDialog.title'
            )}
          />
        </form>
      </div>
      <Button
        type="submit"
        className="Button Button--primary"
        mt={'1.5em'}
        onSubmit={handleSubmit}
        form="timezone"
        isLoading={loading}
        disabled={loading}
      >
        {t('pages.onboarding.components.pickTimezone.saveButton')}
      </Button>
    </div>
  );
}

export default PickTimezone;
