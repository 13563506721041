import { useEffect, useRef, useState } from 'react';
import { Avatar } from '@chakra-ui/react';
import { SettingsIcon, NotAllowedIcon } from '@chakra-ui/icons';
import {
  GrScorecard,
  GrFormDown,
  GrGroup,
  GrCalendar,
  GrUserManager,
  GrAppsRounded,
  GrHomeRounded,
  GrResources
} from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { auth } from 'utilities/firebase.utility';
import { signOut } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import ItemMenu from './components/ItemMenu';
import { useTranslation } from 'react-i18next';
import { resetUser } from 'redux/slices/user';
import { useFetchAndLoad } from 'hooks';
import { updateDemoCoachee } from 'services/coachee.service';
import { resetOnboarding } from 'redux/slices/onboarding';
import { DeleteDemoSessions } from 'services/sessions.service';
import { updateImageCoach } from 'services/coach.service';

function Menu() {
  const dispatch = useDispatch();
  const [isMenuActive, setisMenuActive] = useState(false);
  const {
    mongoID,
    photo,
    name,
    lastname,
    role,
    email,
    company,
    statusUser,
    dataStudio
  } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);
  const [menuItems, setMenuItems] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [loadingLogout, setLoadingLogout] = useState(false); // Nuevo estado para el loader

  const { loading, callEndpoint } = useFetchAndLoad();

  const { t, i18n } = useTranslation('global');

  const language = i18n.language;

  const ref = useRef();
  const navigate = useNavigate();
  const isCoach = role === 'coach';

  const coacheeDemoEmails = [
    'nazareno@bonumcoaching.com',
    'info@bonumcoaching.com',
    'hola@bonumcoaching.com',
    'juan@bonumcoaching.com',
    'ernesto.anguiano@ubertalent.mx',
    'remy.carpentier@morethanperks.com'
  ];

  const evaluators = [
    '651f32467b5573dc16d11b9a',
    '651f32467b5573dc16d11b9b',
    '651f32467b5573dc16d11b9c'
  ];

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const handleMenuClick = () => {
    setisMenuActive(!isMenuActive);
  };

  const homeClick = () => {
    navigate('/');
  };

  const logout = async () => {
    setLoadingLogout(true); // Activar el loader
    try {
      if (coacheeDemoEmails.includes(email) && environment === 'development') {
        await callEndpoint(
          updateDemoCoachee(mongoID, {
            onboardingCompleted: false,
            urlImgCoachee: null,
            timezone: '',
            languages: [],
            focusAreas: [],
            coach: null,
            evaluators: evaluators
          })
        );
        await callEndpoint(DeleteDemoSessions(mongoID));
        dispatch(resetUser());
        dispatch(resetOnboarding());
      } else {
        dispatch(resetUser());
      }
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingLogout(false); // Desactivar el loader
    }
  };

  let menuCoachItems = [
    {
      icon: GrHomeRounded,
      name: t('components.menu.home'),
      onClick: homeClick,
      isResponsive: true
    },
    {
      icon: GrGroup,
      name: t('components.menu.myCoachees'),
      onClick: () => navigate('/mycoachees')
    },
    {
      icon: GrCalendar,
      name: t('components.menu.myCalendar'),
      onClick: () => navigate('/mycalendar')
    }
  ];

  let dropDownCoachItems = [
    {
      icon: SettingsIcon,
      name: t('components.menu.preferences'),
      onClick: () => navigate('/preferences'),
      isResponsive: true
    },
    {
      icon: GrScorecard,
      name: t('components.menu.myEvaluations'),
      onClick: () => navigate('/myevaluations'),
      isResponsive: true
    },
    {
      icon: NotAllowedIcon,
      name: t('components.menu.signOut'),
      onClick: () => logout(),
      isResponsive: true
    }
  ];

  if (dataStudio) {
    dropDownCoachItems.splice(1, 0, {
      icon: GrScorecard,
      name: 'Dashboard 2.0',
      onClick: () => window.open(dataStudio, '_blank'),
      isResponsive: true
    });
  }

  let menuCoacheeItems = [
    {
      icon: GrHomeRounded,
      name: t('components.menu.home'),
      onClick: homeClick,
      isResponsive: true
    },
    {
      icon: GrAppsRounded,
      name: t('components.menu.mySessions'),
      onClick: () => navigate('/mysessions')
    },
    {
      icon: GrCalendar,
      name: t('components.menu.myCalendar'),
      onClick: () => navigate('/mycalendar'),
      disabled: !statusUser
    }
  ];

  let dropDownCoacheeItems = [
    {
      icon: GrUserManager,
      name: t('components.menu.myCoach'),
      onClick: () => navigate('/mycoach')
    },
    {
      icon: GrScorecard,
      name: t('components.menu.myEvaluations'),
      onClick: () => navigate('/myevaluations'),
      isResponsive: true
    },
    {
      icon: SettingsIcon,
      name: t('components.menu.preferences'),
      onClick: () => navigate('/preferences'),
      isResponsive: true
    },
    {
      icon: NotAllowedIcon,
      name: t('components.menu.signOut'),
      onClick: () => logout(),
      isResponsive: true
    }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuActive && ref.current && !ref.current.contains(event.target)) {
        setisMenuActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [isMenuActive]);

  useEffect(() => {
    if (isCoach) {
      setMenuItems(menuCoachItems);
      setDropdownItems(dropDownCoachItems);
    } else {
      setMenuItems(menuCoacheeItems);
      setDropdownItems(dropDownCoacheeItems);
    }
  }, [language]);

  return (
    <nav className="Menu">
      <ul className="Menu__list--principal">
        {menuItems.map((menuItem) => (
          <ItemMenu key={menuItem.name} menuItem={menuItem} />
        ))}
      </ul>
      <div
        className="Menu__dropdown"
        onClick={handleMenuClick}
        onKeyDown={handleMenuClick}
        role="presentation"
        name="menu"
        ref={ref}
      >
        <div className="Menu__profile">
          <Avatar src={photo} name={`${name} ${lastname}`} size="xl" />
          <div className="Menu__profile_data">
            <GrFormDown className="Menu__profile_data_icon" />
          </div>
        </div>
        <div
          className={`Menu__wrapper  ${
            isMenuActive ? 'Menu__wrapper--active' : ''
          } `}
        >
          <ul className="Menu__list">
            <div className="Menu__list_profile">
              <Avatar src={photo} name={`${name} ${lastname}`} size="xl" />
              <div className="Menu__list_profile_data">
                <h3 className="Menu__list_profile_data_name">
                  {name} {lastname}
                </h3>
                <p className="Menu__list_profile_data_role">{role}</p>
                {company && (
                  <p className="Menu__list_profile_data_role">{company}</p>
                )}
                <p className="Menu__list_profile_data_email">{email}</p>
              </div>
            </div>
            <div className="Menu__list_nav">
              {menuItems.map((menuItem) => (
                <ItemMenu key={menuItem.name} menuItem={menuItem} />
              ))}
            </div>
            {dropdownItems.map((menuItem) => (
              <ItemMenu key={menuItem.name} menuItem={menuItem} />
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
