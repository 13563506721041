import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import translateFocusArea from 'utilities/translateFocusArea.utility';

function Coachinfo({ coach }) {
  const { t, i18n } = useTranslation('global');

  const [translatedFocusAreas, setTranslatedFocusAreas] = useState([]);

  useEffect(() => {
    const translateFocusAreas = async () => {
      try {
        const translatedAreas = await Promise.all(
          coach?.focusAreas?.map(async (area) => {
            const translatedText = await translateFocusArea(area);
            return translatedText;
          })
        );

        setTranslatedFocusAreas(translatedAreas);
      } catch (error) {
        console.error('Error translating focus areas:', error);
      }
    };

    translateFocusAreas();
  }, [coach?.focusAreas, i18n.language]);

  return (
    <div className="MySessions__coach">
      <Image
        src={coach.urlImgCoach}
        objectFit="cover"
        className="MySessions__coach_photo"
      />
      <div className="MySessions__coach_data">
        <p className="MySessions__coach_data_name">{`${coach.name} ${coach.lastname}`}</p>
        <div className="MySessions__coach_data_focus-areas">
          <h5>{t('pages.onboarding.components.selectedCoach.focusArea')}</h5>
          <div className="MySessions__coach_data_focus-areas_areas">
            {translatedFocusAreas?.map((translatedArea) => (
              <p
                key={translatedArea._id}
                className="MySessions__coach_data_focus-areas_area"
              >
                {translatedArea}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coachinfo;
